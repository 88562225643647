import townhouseImage from "../images/Main/townhouse.png";
import evolutionImage from "../images/Main/evolition.png";
import apartImage from "../images/Main/apart.png";
import futuristImage from "../images/Main/futurist.jpg";
import leoImage from "../images/Main/Leo.jpg";
import mirImage from "../images/Main/first-direction.png";
import aristoImage from "../images/Main/Aristo.jpg";
import pushkinImage from "../images/Main/pushkin.jpg";

// team

import worker1 from "../images/Team/worker-1.png";
import worker2 from "../images/Team/worker-2.png";
import worker3 from "../images/Team/worker-3.png";
import worker4 from "../images/Team/worker-4.png";
import worker5 from "../images/Team/worker-5.png";
import worker6 from "../images/Team/worker-6.png";
import worker7 from "../images/Team/worker-7.png";
import worker8 from "../images/Team/worker-8.png";
import worker9 from "../images/Team/worker-9.png";
import worker10 from "../images/Team/worker-10.png";
import worker11 from "../images/Team/worker-11.png";
import worker12 from "../images/Team/worker-12.png";
import worker13 from "../images/Team/worker-13.png";
import worker14 from "../images/Team/worker-14.png";
import worker15 from "../images/Team/worker-15.png";
import worker16 from "../images/Team/worker-16.png";
import worker17 from "../images/Team/worker-17.png";
import worker18 from "../images/Team/worker-18.png";
import worker19 from "../images/Team/worker-19.png";
import worker20 from "../images/Team/worker-20.png";
import worker21 from "../images/Team/worker-21.png";
import worker22 from "../images/Team/worker-22.png";

// TeamGood

import workerGood1 from "../images/TeamGood/worker-1.png";
import workerGood2 from "../images/TeamGood/worker-2.png";
import workerGood3 from "../images/TeamGood/worker-3.png";
import workerGood4 from "../images/TeamGood/worker-4.png";
import workerGood5 from "../images/TeamGood/worker-5.png";
import workerGood6 from "../images/TeamGood/worker-6.png";
import workerGood7 from "../images/TeamGood/worker-7.png";
import workerGood8 from "../images/TeamGood/worker-8.png";
import workerGood9 from "../images/TeamGood/worker-9.png";
import workerGood10 from "../images/TeamGood/worker-10.png";
import workerGood11 from "../images/TeamGood/worker-11.png";
import workerGood12 from "../images/TeamGood/worker-12.png";
import workerGood13 from "../images/TeamGood/worker-13.png";
import workerGood14 from "../images/TeamGood/worker-14.png";
import workerGood15 from "../images/TeamGood/worker-15.png";
import workerGood16 from "../images/TeamGood/worker-16.png";
import workerGood17 from "../images/TeamGood/worker-17.png";
import workerGood18 from "../images/TeamGood/worker-18.png";
import workerGood19 from "../images/TeamGood/worker-19.png";
import workerGood20 from "../images/TeamGood/worker-20.png";
import workerGood21 from "../images/TeamGood/worker-21.png";
import workerGood22 from "../images/TeamGood/worker-22.png";

export const works = [
  {
    id: 1,
    metrs: "242 м²",
    city: "Москва",
    name: "Таунхаус",
    background: townhouseImage,
  },
  {
    id: 2,
    metrs: "85 м²",
    city: "Санкт-Петербург",
    name: "ЖК «Е.волюция»",
    background: evolutionImage,
  },
  {
    id: 3,
    metrs: "160 м²",
    city: "Лен. область",
    name: "Апарт отель",
    background: apartImage,
  },
  {
    id: 4,
    metrs: "129 м²",
    city: "Санкт-Петербург",
    name: "ЖК «Футурист»",
    background: futuristImage,
  },
];

export const realizedWorks = [
  {
    id: 1,
    metrs: "120 м²",
    city: "Санкт-Петербург",
    name: "ЖК «Леонтьевский Мыс»",
    background: leoImage,
  },
  {
    id: 2,
    metrs: "130 м²",
    city: "Санкт-Петербург",
    name: "ЖК «Мироздание»",
    background: mirImage,
  },
  {
    id: 3,
    metrs: "100 м²",
    city: "Санкт-Петербург",
    name: "ЖК «Аристократ»",
    background: aristoImage,
  },
  {
    id: 4,
    metrs: "250 м²",
    city: "Ленинградская область г. Пушкин",
    name: "Двухэтажная квартира в Пушкине",
    background: pushkinImage,
  },
];

export const points = [
  {
    id: 1,
    first: "Мы подберем для Вас дизайнера, исходя из ваших потребностей",
    second:
      "Назначим управляющего проектом, который подберет всю команду для реализации: строители, смежные подрядчики и поставщики",
  },
  {
    id: 2,
    first:
      "Предложим планировочное решение и концептуальные решения для вашей квартиры",
    second: "Согласуем план производственных работ с УК жилого комплекса",
  },
  {
    id: 3,
    first: "Разработаем 3D визуализацию будущего интерьера",
    second: "Выполним строительно-монтажные работы с соответствии с графиком:",
    list: [
      "прокладка инженерных сетей и монтаж перегородок",
      "черновой этап отделки",
      "предчистовой этап отделки",
      "чистовой этап отделки",
      "установка и сборка мебели",
      "сдача объекта",
    ],
  },
  {
    id: 4,
    first: "Соберем комплектацию отделочных материалов и предметов интерьера",
    second: "Скоординируем работы всех исполнителей и поставщиков",
  },
  {
    id: 5,
    first: "Спроектируем техническую рабочую документацию и инженерный проект",
    second: "Обеспечим еженедельные совещания, фото и видеоотчеты",
  },
  {
    id: 6,
    first:
      "Подготовим объект к началу ремонтных работ: составим бюджет проекта, смету на ремонт и график реализации проекта",
    second: "Подготовим объект к сдаче, проведем клининг",
  },
];

export const persons = [
  {
    id: 1,
    name: "Владислав",
    position: "Основатель компании",
    image: worker1,
    imageGood: workerGood1,
  },
  {
    id: 2,
    name: "Дарья",
    position: "Исполнительный директор",
    image: worker2,
    imageGood: workerGood2,
  },
  {
    id: 3,
    name: "Сергей",
    position: "Управляющий стройкой",
    image: worker3,
    imageGood: workerGood3,
  },
  {
    id: 4,
    name: "Денис",
    position: "Управляющий стройкой",
    image: worker4,
    imageGood: workerGood4,
  },
  {
    id: 5,
    name: "Софья",
    position: "Дизайнер",
    image: worker5,
    imageGood: workerGood5,
  },
  {
    id: 6,
    name: "Юлия",
    position: "Дизайнер",
    image: worker6,
    imageGood: workerGood6,
  },
  {
    id: 7,
    name: "Ольга",
    position: "Дизайнер",
    image: worker7,
    imageGood: workerGood7,
  },
  {
    id: 8,
    name: "Юлия",
    position: "Дизайнер",
    image: worker8,
    imageGood: workerGood8,
  },
  {
    id: 9,
    name: "Алена",
    position: "Дизайнер",
    image: worker9,
    imageGood: workerGood9,
  },
  {
    id: 10,
    name: "Антонина",
    position: "Дизайнер",
    image: worker10,
    imageGood: workerGood10,
  },
  {
    id: 11,
    name: "Дарья",
    position: "Визуализатор",
    image: worker11,
    imageGood: workerGood11,
  },
  {
    id: 12,
    name: "Юлия",
    position: "Дизайнер",
    image: worker12,
    imageGood: workerGood12,
  },
  {
    id: 13,
    name: "Алина",
    position: "Дизайнер",
    image: worker13,
    imageGood: workerGood13,
  },
  {
    id: 14,
    name: "Елена",
    position: "Дизайнер",
    image: worker14,
    imageGood: workerGood14,
  },
  {
    id: 15,
    name: "Анастасия",
    position: "Визуализатор",
    image: worker15,
    imageGood: workerGood15,
  },
  {
    id: 16,
    name: "Инна",
    position: "Визуализатор",
    image: worker16,
    imageGood: workerGood16,
  },
  {
    id: 17,
    name: "Анна",
    position: "Дизайнер",
    image: worker17,
    imageGood: workerGood17,
  },
  {
    id: 18,
    name: "Константин",
    position: "Инженер-сметчик",
    image: worker18,
    imageGood: workerGood18,
  },
  {
    id: 19,
    name: "Сергей",
    position: "Производитель работ",
    image: worker19,
    imageGood: workerGood19,
  },
  {
    id: 20,
    name: "Олег",
    position: "Производитель работ",
    image: worker20,
    imageGood: workerGood20,
  },
  {
    id: 21,
    name: "Андрей",
    position: "Производитель работ",
    image: worker21,
    imageGood: workerGood21,
  },
  {
    id: 22,
    name: "Вадим",
    position: "Производитель работ",
    image: worker22,
    imageGood: workerGood22,
  },
];

export const drumSettings = [
  {
    id: 1,
    name: "Дизайнер",
    params: [
      "Создает дизайн-проект и ведет авторский надзор.",
      "Художественно оформляет интерьер. Подбирает поставщиков, проверяет договоры и счета, составляет график закупок.",
    ],
    images: [
      worker5,
      worker6,
      worker7,
      worker8,
      worker9,
      worker10,
      worker12,
      worker13,
      worker14,
      worker17,
    ],
  },
  {
    id: 2,
    name: "Инженер-сметчик",
    params: [
      "Составляет смету на ремонт.",
      "Отвечает за техническую составляющую проекта – инженерные сети и конструктивные решения. Проверяет корректность всех чертежей.",
    ],
    images: [worker18],
  },
  {
    id: 3,
    name: "Управляющий проектом",
    params: [
      "Контролирует исполнение графика стройки, затраты по проекту и соблюдении сроков реализации.",
      "Организует доставку материалов согласно графику стройки. Принимает товар на объекте и проверяет на наличие брака.",
    ],
    images: [worker3, worker4],
  },
  {
    id: 4,
    name: "Производитель работ",
    params: [
      "Обеспечивает строительные процессы на объекте: материалами, инструментами, исполнителями. Подбирает и организовывает исполнителей согласно графику стройки.",
    ],
    images: [worker19, worker20, worker21, worker22],
  },
  {
    id: 5,
    name: "Визуализатор",
    params: [
      "Создает трехмерные компьютерные визуализации интерьера жилых пространств.",
    ],
    images: [worker11, worker15],
  },
];

export const advantages = [
  {
    id: 1,
    title: "Гарантия 5 лет по договору",
    text: "Гарантия на работы с технической поддержкой заказчика даже после окончания гарантийного срока.",
  },
  {
    id: 2,
    title: "Приемка квартиры у застройщика",
    text: "Проверим, что квартира действительно соответствует всем требованиям, заявленным в документах.",
  },
  {
    id: 3,
    title: "Обоснованная стоимость всех видов услуг",
    text: "Начиная с проектных изысканий и заканчивая декорированием, с гарантией неизменности цены.",
  },
  {
    id: 4,
    title: "Хорошие скидки",
    text: "Гарантируем реальные скидки на строительные и отделочные материалы, мебель, текстиль и аксессуары, используемые в проекте. Скидки у партнеров до 20%.",
  },
  {
    id: 5,
    title: "Все решения в одном месте",
    text: "От согласования перепланировки и дизайн-проекта до мебели и декора. Экономим ваше время и силы.",
  },
  {
    id: 6,
    title: "Большой штат узкопрофильных специалистов",
    text: "Наша команда – это дизайнеры, архитекторы, сметчики, строители, прорабы, узкопрофильные мастера. У всех сотрудников профильное образование.",
  },
  {
    id: 7,
    title: "Соблюдение сроков",
    text: "Гарантируем неукоснительное соблюдение сроков и графика реализации проекта, независимо от его сложности и объема.",
  },
  {
    id: 8,
    title: "Ремонт квартиры «под тапочки»",
    text: "Делаем честный ремонт любой квартиры под ключ по принципу «заезжай и живи» без ненужных материалов и лишних технических решений.",
  },
];

export const quizSteps = [
  {
    number: 1,
    question: "Какой у вас объект?",
    param: "project",
    answers: [
      "Квартира новостройка",
      "Квартира старый фонд",
      "Таунхаус",
      "Дом",
    ],
  },
  {
    number: 2,
    question: "Какая площадь помещения?",
    param: "area",
    answers: [
      "До 80 м²",
      "От 80 до 150 м²",
      "От 150 до 300 м²",
      "Более 300 м²",
    ],
  },
  {
    number: 3,
    question: "Какой стиль интерьера вам нравится?",
    param: "style",
    answers: [
      "Современный",
      "Классический",
      "Минимализм",
      "Скандинавский",
      "Хай-тек",
      "Пока не знаю",
    ],
  },
  {
    number: 4,
    question: "Когда вы планируете закончить ремонт?",
    param: "deadline",
    answers: [
      "На новый год",
      "К 1 сентября",
      "Ко дню рождения",
      "Через год и более",
      "Сроки не важны",
    ],
  },
];

export const feedbacks = [
  {
    id: 1,
    text: "С трудом, но уговорила своего мужа на ремонт. Компанию подыскала сама. Наткнулась на ребят, которые уже 18 лет в бизнесе, дают гарантию на 5 лет. Нам нужен был ремонт квартиры под ключ. Всё сделали в сроки, как и обещали. Дизайн делала компания, нам очень понравилось. Квартиру просто не узнать. Очень довольны. Да, вложились мы хорошо, но каждая затраченная копейка окупилась. Рекомендую.",
    name: "Виктория",
    object: "ЖК “Familia”",
    city: "Санкт-Петербург",
  },
  {
    id: 2,
    text: "С трудом, но уговорила своего мужа на ремонт. Компанию подыскала сама. Наткнулась на ребят, которые уже 18 лет в бизнесе, дают гарантию на 5 лет. Нам нужен был ремонт квартиры под ключ. Всё сделали в сроки, как и обещали. Дизайн делала компания, нам очень понравилось. Квартиру просто не узнать. Очень довольны. Да, вложились мы хорошо, но каждая затраченная копейка окупилась. Рекомендую.",
    name: "Виктория",
    object: "ЖК “Familia”",
    city: "Санкт-Петербург",
  },
  {
    id: 3,
    text: "С трудом, но уговорила своего мужа на ремонт. Компанию подыскала сама. Наткнулась на ребят, которые уже 18 лет в бизнесе, дают гарантию на 5 лет. Нам нужен был ремонт квартиры под ключ. Всё сделали в сроки, как и обещали. Дизайн делала компания, нам очень понравилось. Квартиру просто не узнать. Очень довольны. Да, вложились мы хорошо, но каждая затраченная копейка окупилась. Рекомендую.",
    name: "Виктория",
    object: "ЖК “Familia”",
    city: "Санкт-Петербург",
  },
  {
    id: 4,
    text: "С трудом, но уговорила своего мужа на ремонт. Компанию подыскала сама. Наткнулась на ребят, которые уже 18 лет в бизнесе, дают гарантию на 5 лет. Нам нужен был ремонт квартиры под ключ. Всё сделали в сроки, как и обещали. Дизайн делала компания, нам очень понравилось. Квартиру просто не узнать. Очень довольны. Да, вложились мы хорошо, но каждая затраченная копейка окупилась. Рекомендую.",
    name: "Виктория",
    object: "ЖК “Familia”",
    city: "Санкт-Петербург",
  },
  {
    id: 5,
    text: "С трудом, но уговорила своего мужа на ремонт. Компанию подыскала сама. Наткнулась на ребят, которые уже 18 лет в бизнесе, дают гарантию на 5 лет. Нам нужен был ремонт квартиры под ключ. Всё сделали в сроки, как и обещали. Дизайн делала компания, нам очень понравилось. Квартиру просто не узнать. Очень довольны. Да, вложились мы хорошо, но каждая затраченная копейка окупилась. Рекомендую.",
    name: "Виктория",
    object: "ЖК “Familia”",
    city: "Санкт-Петербург",
  },
];

export const questions = [
  {
    question: "Что входит в дизайн-проект?",
    answer:
      "Дизайн-проект включает в себя разработку концепции интерьера, планировочные решения, подбор материалов, мебели и декоративных элементов, а также создание чертежей и схем для строителей. В проект также входят визуализации, которые помогут вам увидеть, как будет выглядеть конечный результат.",
  },
  {
    question: "Кто работает над дизайн-проектом?",
    answer:
      "Над дизайн-проектом работает команда профессионалов: дизайнер интерьера, архитектор, визуализатор, а также технические специалисты, такие как инженеры, если проект требует сложных решений.",
  },
  {
    question:
      "Чем услуга «дизайн-проект» отличается от услуги «интерьер квартиры под ключ»?",
    answer:
      "Услуга «дизайн-проект» включает разработку всех аспектов будущего интерьера, но не включает в себя реализацию. «Интерьер квартиры под ключ» — это комплексная услуга, которая включает в себя не только создание дизайн-проекта, но и его полное воплощение: от закупки материалов до проведения строительных и отделочных работ.",
  },
  {
    question: "Я не знаю, какой стиль хочу видеть в своём доме. Что делать?",
    answer:
      "Начните с просмотра различных примеров интерьеров, чтобы определить, что вам нравится. Обратитесь к дизайнеру, который предложит несколько вариантов стилей на основе ваших предпочтений и функциональных требований. Вместе вы сможете выбрать тот стиль, который вам ближе всего.",
  },
  {
    question: "Зачем нужен авторский надзор?",
    answer:
      "Авторский надзор необходим для того, чтобы контролировать соответствие проводимых работ утвержденному дизайн-проекту. Дизайнер следит за тем, чтобы все материалы, мебель и декоративные элементы соответствовали проекту, а также оперативно вносит необходимые изменения в процессе реализации.",
  },
  {
    question: "Для чего нужна комплектация объекта?",
    answer:
      "Комплектация объекта — это услуга по подбору и закупке всех необходимых материалов, мебели и декоративных элементов для вашего интерьера. Это помогает сэкономить время и избежать ошибок при выборе и доставке материалов, а также обеспечить полное соответствие проекта.",
  },
  {
    question: "Сколько вариантов планировок вы делаете?",
    answer:
      "Обычно предоставляется 2-3 варианта планировочных решений, которые соответствуют вашим запросам и требованиям. Это позволяет выбрать оптимальный вариант, учитывая все пожелания и функциональные задачи.",
  },
  // {
  //   question: "Успеете ли вы создать полный проект за 1 месяц?",
  //   answer:
  //     "Контролирует исполнение графика стройки, затраты по проекту и соблюдении сроков реализации",
  // },
  // {
  //   question:
  //     "Есть ли у вас проверенные строительные бригады, которым можно доверять?",
  //   answer:
  //     "Контролирует исполнение графика стройки, затраты по проекту и соблюдении сроков реализации",
  // },
  // {
  //   question: "Входят ли в состав рабочей документации инженерные проекты?",
  //   answer:
  //     "Контролирует исполнение графика стройки, затраты по проекту и соблюдении сроков реализации",
  // },
  // {
  //   question:
  //     "Можно ли начать ремонт параллельно с разработкой дизайн-проекта?",
  //   answer:
  //     "Контролирует исполнение графика стройки, затраты по проекту и соблюдении сроков реализации",
  // },
  // {
  //   question:
  //     "Если еще не получили ключи от квартиры, можно ли начать работу над проектом?",
  //   answer:
  //     "Контролирует исполнение графика стройки, затраты по проекту и соблюдении сроков реализации",
  // },
  // {
  //   question: "Если мне не понравится работа дизайнера?",
  //   answer:
  //     "Контролирует исполнение графика стройки, затраты по проекту и соблюдении сроков реализации",
  // },
  // {
  //   question: "Занимаетесь ли вы закупками под ключ?",
  //   answer:
  //     "Контролирует исполнение графика стройки, затраты по проекту и соблюдении сроков реализации",
  // },
];
