const importAll = (r) =>
  r.keys().reduce((acc, item) => {
    acc[item.replace("./", "")] = r(item);
    return acc;
  }, {});

const extractNumber = (filename) => {
  const match = filename.match(/(\d+)/);
  return match ? parseInt(match[0], 10) : Infinity;
};

const filterImages = (images, exclude) =>
  Object.keys(images)
    .filter((image) => !exclude.includes(image))
    .sort((a, b) => extractNumber(a) - extractNumber(b))
    .map((key) => images[key]);

// Импорт всех изображений для каждой галереи
const futuristImages = importAll(
  require.context("../images/gallery/futurist", false, /\.(png|jpe?g|svg)$/)
);
const kudrovoImages = importAll(
  require.context("../images/gallery/kudrovo", false, /\.(png|jpe?g|svg)$/)
);
const royalCapitalImages = importAll(
  require.context(
    "../images/gallery/royal-capital",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const kalinaParkImages = importAll(
  require.context("../images/gallery/kalina-park", false, /\.(png|jpe?g|svg)$/)
);
const frontQuarterImages = importAll(
  require.context(
    "../images/gallery/front-quarter",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const resortImages = importAll(
  require.context("../images/gallery/resort", false, /\.(png|jpe?g|svg)$/)
);
const aparthotelImages = importAll(
  require.context("../images/gallery/aparthotel", false, /\.(png|jpe?g|svg)$/)
);
const townhouseImages = importAll(
  require.context("../images/gallery/townhouse", false, /\.(png|jpe?g|svg)$/)
);
const evolutionImages = importAll(
  require.context("../images/gallery/evolution", false, /\.(png|jpe?g|svg)$/)
);
const restaurantImages = importAll(
  require.context("../images/gallery/restaurant", false, /\.(png|jpe?g|svg)$/)
);
const holidayImages = importAll(
  require.context("../images/gallery/holiday", false, /\.(png|jpe?g|svg)$/)
);

const aleksandrovskayaImages = importAll(
  require.context(
    "../images/RealizedGallery/aleksandrovskaya",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const aristokratImages = importAll(
  require.context(
    "../images/RealizedGallery/aristokrat",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const burgerImages = importAll(
  require.context(
    "../images/RealizedGallery/burger",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const leontievskyImages = importAll(
  require.context(
    "../images/RealizedGallery/leontievsky",
    false,
    /\.(png|jpe?g|svg|mp4)$/
  )
);
const kavgolovoImages = importAll(
  require.context(
    "../images/RealizedGallery/kavgolovo",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const masterSpaceImages = importAll(
  require.context(
    "../images/RealizedGallery/master-space",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const mirozdanieImages = importAll(
  require.context(
    "../images/RealizedGallery/mirozdanie",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const moskovskyAvenueImages = importAll(
  require.context(
    "../images/RealizedGallery/moskovsky-avenue",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const pushkinImages = importAll(
  require.context(
    "../images/RealizedGallery/pushkin",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const roschinoImages = importAll(
  require.context(
    "../images/RealizedGallery/roschino",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const zelenogorskImages = importAll(
  require.context(
    "../images/RealizedGallery/zelenogorsk",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

export const gallery = [
  {
    id: 1,
    name: "burger",
    location: "Санкт-Петербург, Севкабель Порт",
    apart: "Реализованный проект бургерной MeatUp в Севкабель порт",
    title: "Реализованный проект бургерной MeatUp в Севкабель порт",
    metrs: "47",
    price: "235 000",
    priceRealization: "10 000 000",
    projectDevelopment: null,
    realizationPeriod: "3",
    style: "Современный",
    text: "ИСПРАВИТЬ! Особенности: высокие потолки; двустороннее расположение квартиры; просторная планировка, наполненная «воздухом»; два санузла; общесемейное пространство с кухонным фронтом, обеденным столом и угловым диваном в три ряда; большое количество встроенной корпусной мебели под места хранения; многофункциональный кабинет для семьи айтишника и финансиста, состоящий из четырёх рабочих мест (у окна и в центре комнаты) и зоны отдыха (на диване); большая личная комната с объединённой лоджией и гардеробной со скрытым санузлом.",
    intro: burgerImages["intro.png"],
    intro2: burgerImages["intro2.jpg"],
    video: null,
    images: filterImages(burgerImages, ["intro.jpg", "intro2.jpg"]),
    columns: 1,
    objectColumns: 1,
  },
  {
    id: 2,
    name: "pushkin",
    location: "Ленинградская область г. Пушкин",
    apart: "Реализованный проект двухэтажной квартиры в Пушкине",
    title: "Реализованный проект двухэтажной квартиры в Пушкине",
    metrs: "250",
    price: "1 000 000",
    priceRealization: "34 000 000",
    projectDevelopment: null,
    realizationPeriod: "15",
    style: "Эклектика",
    text: "ИСПРАВИТЬ! Особенности: высокие потолки; двустороннее расположение квартиры; просторная планировка, наполненная «воздухом»; два санузла; общесемейное пространство с кухонным фронтом, обеденным столом и угловым диваном в три ряда; большое количество встроенной корпусной мебели под места хранения; многофункциональный кабинет для семьи айтишника и финансиста, состоящий из четырёх рабочих мест (у окна и в центре комнаты) и зоны отдыха (на диване); большая личная комната с объединённой лоджией и гардеробной со скрытым санузлом.",
    intro: pushkinImages["intro.jpg"],
    intro2: pushkinImages["intro2.jpg"],
    video: null,
    images: filterImages(pushkinImages, ["intro.jpg", "intro2.jpg"]),
    columns: 2,
    objectColumns: 1,
  },
  {
    id: 3,
    name: "kavgolovo",
    location: "Ленинградская область, д. Кавголово",
    apart:
      "Реализованный проект загородного дома на берегу Кавголовского озера",
    title:
      "Реализованный проект загородного дома на берегу Кавголовского озера",
    metrs: "650",
    price: "1 200 000",
    priceRealization: "50 000 000",
    projectDevelopment: null,
    realizationPeriod: "9",
    style: "Современный",
    text: "ИСПРАВИТЬ! Особенности: высокие потолки; двустороннее расположение квартиры; просторная планировка, наполненная «воздухом»; два санузла; общесемейное пространство с кухонным фронтом, обеденным столом и угловым диваном в три ряда; большое количество встроенной корпусной мебели под места хранения; многофункциональный кабинет для семьи айтишника и финансиста, состоящий из четырёх рабочих мест (у окна и в центре комнаты) и зоны отдыха (на диване); большая личная комната с объединённой лоджией и гардеробной со скрытым санузлом.",
    intro: kavgolovoImages["intro.jpg"],
    intro2: kavgolovoImages["intro2.jpg"],
    video: null,
    images: filterImages(kavgolovoImages, ["intro.jpg", "intro2.jpg"]),
    columns: 2,
    objectColumns: 1,
  },
  {
    id: 4,
    name: "moskovsky-avenue",
    location: "Санкт-Петербург",
    apart: "Реализованная квартира на Московском проспекте",
    title: "Реализованная квартира на Московском проспекте",
    metrs: "97",
    price: "485 000",
    priceRealization: "20 000 000",
    projectDevelopment: null,
    realizationPeriod: "12",
    style: "Эклектика",
    text: "ИСПРАВИТЬ! Особенности: высокие потолки; двустороннее расположение квартиры; просторная планировка, наполненная «воздухом»; два санузла; общесемейное пространство с кухонным фронтом, обеденным столом и угловым диваном в три ряда; большое количество встроенной корпусной мебели под места хранения; многофункциональный кабинет для семьи айтишника и финансиста, состоящий из четырёх рабочих мест (у окна и в центре комнаты) и зоны отдыха (на диване); большая личная комната с объединённой лоджией и гардеробной со скрытым санузлом.",
    intro: moskovskyAvenueImages["intro.jpeg"],
    intro2: moskovskyAvenueImages["intro2.jpg"],
    video: null,
    images: filterImages(moskovskyAvenueImages, ["intro.jpg", "intro2.jpg"]),
    columns: 1,
    objectColumns: 1,
  },
  {
    id: 5,
    name: "mirozdanie",
    location: "Санкт-Петербург, ЖК «Мироздание»",
    apart: "Реализованный проект квартиры в ЖК «Мироздание»",
    title: "Реализованный проект квартиры в ЖК «Мироздание»",
    metrs: "130",
    price: "600 000",
    priceRealization: "16 000 000",
    projectDevelopment: null,
    realizationPeriod: "10",
    style: "Современный минимализм",
    text: "ИСПРАВИТЬ! Особенности: высокие потолки; двустороннее расположение квартиры; просторная планировка, наполненная «воздухом»; два санузла; общесемейное пространство с кухонным фронтом, обеденным столом и угловым диваном в три ряда; большое количество встроенной корпусной мебели под места хранения; многофункциональный кабинет для семьи айтишника и финансиста, состоящий из четырёх рабочих мест (у окна и в центре комнаты) и зоны отдыха (на диване); большая личная комната с объединённой лоджией и гардеробной со скрытым санузлом.",
    intro: mirozdanieImages["intro.jpg"],
    intro2: mirozdanieImages["intro2.jpg"],
    video: null,
    images: filterImages(mirozdanieImages, ["intro.jpg", "intro2.jpg"]),
    columns: 1,
    objectColumns: 2,
  },
  {
    id: 6,
    name: "master-space",
    location: "Санкт-Петербург",
    apart: "Реализованный проект бьюти-коворкинга Master Space",
    title: "Реализованный проект бьюти-коворкинга Master Space",
    metrs: "140",
    price: "560 000",
    priceRealization: "18 000 000",
    projectDevelopment: null,
    realizationPeriod: "4",
    style: "Современный",
    text: "ИСПРАВИТЬ! Особенности: высокие потолки; двустороннее расположение квартиры; просторная планировка, наполненная «воздухом»; два санузла; общесемейное пространство с кухонным фронтом, обеденным столом и угловым диваном в три ряда; большое количество встроенной корпусной мебели под места хранения; многофункциональный кабинет для семьи айтишника и финансиста, состоящий из четырёх рабочих мест (у окна и в центре комнаты) и зоны отдыха (на диване); большая личная комната с объединённой лоджией и гардеробной со скрытым санузлом.",
    intro: masterSpaceImages["intro.jpg"],
    intro2: masterSpaceImages["intro2.jpg"],
    video: null,
    images: filterImages(masterSpaceImages, ["intro.jpg", "intro2.jpg"]),
    columns: 2,
    objectColumns: 1,
  },
  {
    id: 7,
    name: "roschino",
    location: "Ленинградская область, п. Рощино",
    apart: "Реализованный проект загородного дома в п.  Рощино",
    title: "Реализованный проект загородного дома в п.  Рощино",
    metrs: "250",
    price: "900 000",
    priceRealization: "30 000 000",
    projectDevelopment: null,
    realizationPeriod: "12",
    style: "Современный",
    text: "ИСПРАВИТЬ! Особенности: высокие потолки; двустороннее расположение квартиры; просторная планировка, наполненная «воздухом»; два санузла; общесемейное пространство с кухонным фронтом, обеденным столом и угловым диваном в три ряда; большое количество встроенной корпусной мебели под места хранения; многофункциональный кабинет для семьи айтишника и финансиста, состоящий из четырёх рабочих мест (у окна и в центре комнаты) и зоны отдыха (на диване); большая личная комната с объединённой лоджией и гардеробной со скрытым санузлом.",
    intro: roschinoImages["intro.jpeg"],
    intro2: roschinoImages["intro2.jpg"],
    video: null,
    images: filterImages(roschinoImages, ["intro.jpg", "intro2.jpg"]),
    columns: 2,
    objectColumns: 1,
  },
  {
    id: 8,
    name: "leontievsky",
    location: "Санкт-Петербург, ЖК «Леонтьевский Мыс»",
    apart: "Реализованный проект квартиры в ЖК «Леонтьевский Мыс»",
    title: "Реализованный проект квартиры в ЖК «Леонтьевский Мыс»",
    metrs: "120",
    price: "600 000",
    priceRealization: "17 000 000",
    projectDevelopment: null,
    realizationPeriod: "6",
    style: "Современный",
    text: "ИСПРАВИТЬ! Особенности: высокие потолки; двустороннее расположение квартиры; просторная планировка, наполненная «воздухом»; два санузла; общесемейное пространство с кухонным фронтом, обеденным столом и угловым диваном в три ряда; большое количество встроенной корпусной мебели под места хранения; многофункциональный кабинет для семьи айтишника и финансиста, состоящий из четырёх рабочих мест (у окна и в центре комнаты) и зоны отдыха (на диване); большая личная комната с объединённой лоджией и гардеробной со скрытым санузлом.",
    intro: leontievskyImages["intro.jpg"],
    intro2: leontievskyImages["intro2.jpg"],
    video: "https://vk.com/penta_pro?w=wall-226937411_4%2Fall",
    videoPoster: leontievskyImages["poster.png"],
    images: filterImages(leontievskyImages, ["intro.jpg", "intro2.jpg"]),
    columns: 1,
    objectColumns: 2,
  },
  {
    id: 9,
    name: "leontievsky2",
    location: "Санкт-Петербург, ЖК «Леонтьевский Мыс»",
    apart: "Реализованный проект квартиры в ЖК «Леонтьевский Мыс»",
    title: "Реализованный проект квартиры в ЖК «Леонтьевский Мыс»",
    metrs: "120",
    price: "600 000",
    priceRealization: "17 000 000",
    projectDevelopment: null,
    realizationPeriod: "6",
    style: "Современный",
    text: "ИСПРАВИТЬ! Особенности: высокие потолки; двустороннее расположение квартиры; просторная планировка, наполненная «воздухом»; два санузла; общесемейное пространство с кухонным фронтом, обеденным столом и угловым диваном в три ряда; большое количество встроенной корпусной мебели под места хранения; многофункциональный кабинет для семьи айтишника и финансиста, состоящий из четырёх рабочих мест (у окна и в центре комнаты) и зоны отдыха (на диване); большая личная комната с объединённой лоджией и гардеробной со скрытым санузлом.",
    intro: leontievskyImages["intro.jpg"],
    intro2: leontievskyImages["intro2.jpg"],
    video: leontievskyImages["video.mp4"],
    videoPoster: leontievskyImages["poster.png"],
    images: filterImages(leontievskyImages, [
      "intro.jpg",
      "intro2.jpg",
      "video.mp4",
      "poster.png",
    ]),
    columns: 1,
    objectColumns: 2,
  },
  {
    id: 10,
    name: "aleksandrovskaya",
    location: "Ленинградская область, п. Александровская",
    apart: "Реализованный проект загородного дома в п. Александровская",
    title: "Реализованный проект загородного дома в п. Александровская",
    metrs: "300",
    price: "950 000",
    priceRealization: "38 000 000",
    projectDevelopment: null,
    realizationPeriod: "12",
    style: "Современный",
    text: "ИСПРАВИТЬ! Особенности: высокие потолки; двустороннее расположение квартиры; просторная планировка, наполненная «воздухом»; два санузла; общесемейное пространство с кухонным фронтом, обеденным столом и угловым диваном в три ряда; большое количество встроенной корпусной мебели под места хранения; многофункциональный кабинет для семьи айтишника и финансиста, состоящий из четырёх рабочих мест (у окна и в центре комнаты) и зоны отдыха (на диване); большая личная комната с объединённой лоджией и гардеробной со скрытым санузлом.",
    intro: aleksandrovskayaImages["intro.jpeg"],
    intro2: aleksandrovskayaImages["intro2.jpg"],
    video: null,
    images: filterImages(aleksandrovskayaImages, ["intro.jpg", "intro2.jpg"]),
    columns: 2,
    objectColumns: 1,
  },
  {
    id: 11,
    name: "holiday",
    location: "Ленинградская область",
    apart: "База отдыха",
    title: "База отдыха в Ленинградской области",
    metrs: "405",
    price: "1 600 000",
    priceRealization: "41 000 000",
    projectDevelopment: "50",
    realizationPeriod: null,
    style: "Современный",
    text: "ИСПРАВИТЬ Особенности планировки: постирочная в шкафу прихожей; встроенный «Г»-образный кухонный фронт; винный стеллаж для коллекционных образцов напитка; функциональное пространство гостевой зоны с возможностью присоединения обеденного стола к дивану; балкон, как дополнительная зона отдыха со встроенным креслом, книжным стеллажом и хозяйственным шкафом; спальня с широкой кроватью и прозрачным шкафом-гардеробной, состоящим из нескольких модулей - верхних полок, длинной штанги для одежды и комода.",
    intro: holidayImages["intro.jpg"],
    intro2: holidayImages["intro2.jpg"],
    layoutBefore: holidayImages["before.png"],
    layoutAfter: holidayImages["after.png"],
    images: filterImages(holidayImages, [
      "intro.jpg",
      "intro2.jpg",
      "before.png",
      "after.png",
    ]),
    columns: 2,
    objectColumns: 2,
  },
  {
    id: 12,
    name: "aristokrat",
    location: "Санкт-Петербург, ЖК «Аристократ»",
    apart: "Реализованная квартира в ЖК «Аристократ»",
    title: "Реализованная квартира в ЖК «Аристократ»",
    metrs: "100",
    price: "500 000",
    priceRealization: "14 000 000",
    projectDevelopment: null,
    realizationPeriod: "8",
    style: "Современный",
    text: "ИСПРАВИТЬ! Особенности: высокие потолки; двустороннее расположение квартиры; просторная планировка, наполненная «воздухом»; два санузла; общесемейное пространство с кухонным фронтом, обеденным столом и угловым диваном в три ряда; большое количество встроенной корпусной мебели под места хранения; многофункциональный кабинет для семьи айтишника и финансиста, состоящий из четырёх рабочих мест (у окна и в центре комнаты) и зоны отдыха (на диване); большая личная комната с объединённой лоджией и гардеробной со скрытым санузлом.",
    intro: aristokratImages["intro.jpg"],
    intro2: aristokratImages["intro2.jpg"],
    video: null,
    images: filterImages(aristokratImages, ["intro.jpg", "intro2.jpg"]),
    columns: 1,
    objectColumns: 1,
  },
  {
    id: 13,
    name: "kalina-park",
    location: "Санкт-Петербург",
    apart: "ЖК «Калина парк»",
    title: "Квартира в ЖК «Калина парк»",
    metrs: "69",
    price: "276 000",
    priceRealization: "6 800 000",
    projectDevelopment: "31",
    realizationPeriod: null,
    style: "Современный с элементами скандинавского",
    text: "Особенности планировки: вольер для собаки в прихожей с видом на общесемейное пространство; раздельный санузел; «П»-образная кухня со встроенным столом и дополнительными местами хранения за счет увеличения кухонного фронта на глубину короба стояка труб; кабинет с проекционным экраном для просмотра фильмов (с возможностью в будущем преобразования данного помещения в детскую); спальня с личной застекленной гардеробной.",
    intro: kalinaParkImages["intro.jpg"],
    intro2: kalinaParkImages["intro2.jpg"],
    layoutBefore: kalinaParkImages["before.png"],
    layoutAfter: kalinaParkImages["after.png"],
    images: filterImages(kalinaParkImages, [
      "intro.jpg",
      "intro2.jpg",
      "before.png",
      "after.png",
    ]),
    columns: 1,
    objectColumns: 2,
  },
  {
    id: 14,
    name: "zelenogorsk",
    location: "Ленинградская область, г. Зеленогорск",
    apart: "Реализованный проект СПА-комплекса в Зеленогорске",
    title: "Реализованный проект СПА-комплекса в Зеленогорске",
    metrs: "330",
    price: "1 250 000",
    priceRealization: "44 000 000",
    projectDevelopment: null,
    realizationPeriod: "4",
    style: "Современный",
    text: "ИСПРАВИТЬ! Особенности: высокие потолки; двустороннее расположение квартиры; просторная планировка, наполненная «воздухом»; два санузла; общесемейное пространство с кухонным фронтом, обеденным столом и угловым диваном в три ряда; большое количество встроенной корпусной мебели под места хранения; многофункциональный кабинет для семьи айтишника и финансиста, состоящий из четырёх рабочих мест (у окна и в центре комнаты) и зоны отдыха (на диване); большая личная комната с объединённой лоджией и гардеробной со скрытым санузлом.",
    intro: zelenogorskImages["intro.jpg"],
    intro2: zelenogorskImages["intro2.jpg"],
    video: null,
    images: filterImages(zelenogorskImages, ["intro.jpg", "intro2.jpg"]),
    columns: 2,
    objectColumns: 1,
  },
  {
    id: 15,
    name: "royal-capital",
    location: "Санкт-Петербург",
    apart: "ЖК «Царская столица»",
    title: "Квартира в ЖК «Царская столица»",
    metrs: "129",
    price: "516 000",
    priceRealization: "23 000 000",
    projectDevelopment: "36",
    realizationPeriod: null,
    style: "Современный с элементами модерна",
    text: "Особенности планировки: половина площади квартиры - это развитое общесемейное пространство с несколькими функциональными зонами - двухрядная кухня с барной стойкой, столовая, диванная группа и каминная зона отдыха; в составе площадей - три санузла и отдельная постирочная; плавное перетекание движения из общего пространства в личное за счет скругления углов стен детской и санузла; спальня хозяев с большой гардеробной в изголовье кровати, скрытой дверью в личный санузел и зимним садом на лоджии.",
    intro: royalCapitalImages["intro.jpg"],
    intro2: royalCapitalImages["intro2.jpg"],
    layoutBefore: royalCapitalImages["before.png"],
    layoutAfter: royalCapitalImages["after.png"],
    images: filterImages(royalCapitalImages, [
      "intro.jpg",
      "intro2.jpg",
      "before.png",
      "after.png",
    ]),
    columns: 2,
    objectColumns: 1,
  },
  {
    id: 16,
    name: "front-quarter",
    location: "Санкт-Петербург",
    apart: "ЖК «Парадный квартал»",
    title: "Классическая квартира в Санкт-Петербурге",
    metrs: "78",
    price: "312 000",
    priceRealization: "16 000 000",
    projectDevelopment: "45",
    realizationPeriod: null,
    style: "Неоклассика",
    text: "Особенности планировки: два санузла; общесемейное пространство со встроенным в угловую нишу кухонным фронтом; детская с эркером и несколькими функциональными зонами (работа, отдых, развлечения); спальня хозяйки-предпринимателя с рабочим кабинетом на утепленном балконе и гардеробной, включающим место для сейфа; большое количество корпусной встроенной мебели для хранения; место в каждой личной комнате и санузлах под умную станцию с Алисой.",
    intro: frontQuarterImages["intro.jpg"],
    intro2: frontQuarterImages["intro2.jpg"],
    layoutBefore: frontQuarterImages["before.png"],
    layoutAfter: frontQuarterImages["after.png"],
    images: filterImages(frontQuarterImages, [
      "intro.jpg",
      "intro2.jpg",
      "before.png",
      "after.png",
    ]),
    columns: 1,
    objectColumns: 2,
  },
  {
    id: 17,
    name: "kudrovo",
    location: "Санкт-Петербург",
    apart: "ЖК «ID KUDROVO»",
    title: "Квартира в ЖК «ID KUDROVO»",
    metrs: "31",
    price: "124 000",
    priceRealization: "3 200 000",
    projectDevelopment: "29",
    realizationPeriod: null,
    style: "Современный с элементами лофта",
    text: "Особенности планировки: постирочная в шкафу прихожей; встроенный «Г»-образный кухонный фронт; винный стеллаж для коллекционных образцов напитка; функциональное пространство гостевой зоны с возможностью присоединения обеденного стола к дивану; балкон, как дополнительная зона отдыха со встроенным креслом, книжным стеллажом и хозяйственным шкафом; спальня с широкой кроватью и прозрачным шкафом-гардеробной, состоящим из нескольких модулей - верхних полок, длинной штанги для одежды и комода.",
    intro: kudrovoImages["intro.jpg"],
    intro2: kudrovoImages["intro2.jpg"],
    layoutBefore: kudrovoImages["before.png"],
    layoutAfter: kudrovoImages["after.png"],
    images: filterImages(kudrovoImages, [
      "intro.jpg",
      "intro2.jpg",
      "before.png",
      "after.png",
    ]),
    columns: 1,
    objectColumns: 2,
  },
  {
    id: 18,
    name: "townhouse",
    location: "Москва",
    apart: "Таунхаус",
    title: "Таунхаус",
    metrs: "242",
    price: "612 000",
    priceRealization: "32 000 000",
    projectDevelopment: "42",
    realizationPeriod: null,
    style: "Современный с элементами модерна",
    text: "Особенности: трёхуровневый таунхаус; лестница, ведущая в тамбур из подземной парковки; «Г» образная кухня с островом и барной стойкой; гостиная с развитой диванной группой, каминной и столовой зоной; наличие в интерьере открытых стеллажей для расположения коллекции заказчика - мишек «Bearbrick»; объединение уровней внутри (встроенной лестницей в центре пространств) и снаружи (винтовой лестницей с террас); три санузла; две равнозначные детские, включающие в себя зоны отдыха, учебы и игр; спальня с личным санузлом и лестницей на следующий этаж, где располагается кабинет и выход на эксплуатируемую плоскую кровлю.",
    intro: townhouseImages["intro.jpg"],
    intro2: townhouseImages["intro2.jpg"],
    layoutBefore: townhouseImages["before.png"],
    layoutAfter: townhouseImages["after.png"],
    images: filterImages(townhouseImages, [
      "intro.jpg",
      "intro2.jpg",
      "before.png",
      "after.png",
    ]),
    columns: 2,
    objectColumns: 2,
  },
  {
    id: 19,
    name: "aparthotel",
    location: "Ленинградская область",
    apart: "Апарт-отель",
    title: "Апарт-отель",
    metrs: "160",
    price: "640 000",
    priceRealization: "16 000 000",
    projectDevelopment: "40",
    realizationPeriod: null,
    style: "Современный",
    text: "Особенности: двухуровневые апартаменты со вторым светом и панорамным остеклением; три санузла; открытая прихожая, объединенная с просторным общесемейным пространством; кухня с барной стойкой и столовой зоной на 8 посадочных мест; две личные комнаты; мастер-спальня, размещенная на антресоли второго уровня с большой островной гардеробной, рабочим кабинетом и санузлом с двумя умывальниками и отдельно стоящей ванной.",
    intro: aparthotelImages["intro.jpg"],
    intro2: aparthotelImages["intro2.jpg"],
    layoutBefore: aparthotelImages["before.png"],
    layoutAfter: aparthotelImages["after.png"],
    images: filterImages(aparthotelImages, [
      "intro.jpg",
      "intro2.jpg",
      "before.png",
      "after.png",
    ]),
    columns: 2,
    objectColumns: 1,
  },
  {
    id: 20,
    name: "restaurant",
    location: "Санкт-Петербург",
    apart: "Ресторан",
    title: "Ресторан",
    metrs: "200",
    price: "1 000 000",
    priceRealization: "24 000 000",
    projectDevelopment: "45",
    realizationPeriod: null,
    style: "Современный",
    text: "ИСПРАВИТЬ Особенности планировки: постирочная в шкафу прихожей; встроенный «Г»-образный кухонный фронт; винный стеллаж для коллекционных образцов напитка; функциональное пространство гостевой зоны с возможностью присоединения обеденного стола к дивану; балкон, как дополнительная зона отдыха со встроенным креслом, книжным стеллажом и хозяйственным шкафом; спальня с широкой кроватью и прозрачным шкафом-гардеробной, состоящим из нескольких модулей - верхних полок, длинной штанги для одежды и комода.",
    intro: restaurantImages["intro.jpg"],
    intro2: restaurantImages["intro2.jpg"],
    layoutBefore: restaurantImages["before.png"],
    layoutAfter: restaurantImages["after.png"],
    images: filterImages(restaurantImages, [
      "intro.jpg",
      "intro2.jpg",
      "before.png",
      "after.png",
    ]),
    columns: 1,
    objectColumns: 2,
  },
  {
    id: 21,
    name: "resort",
    location: "Сочи",
    apart: "ЖК «Курортный»",
    title: "Квартира в Сочи в курортном районе",
    metrs: "42",
    price: "168 000",
    priceRealization: "9 000 000",
    projectDevelopment: "29",
    realizationPeriod: null,
    style: "Скандинавский с элементами лофта",
    text: "Квартира проектировалась исходя из ее функции - временного проживания семьи в курортный сезон.  Особенности планировки: свободная планировка; объединённая с прихожей кухня-гостиная; угловой раздвижной диван и встроенная деревянная кушетка у окна общесемейной зоны; кухонный фронт, встроенный в нишу из царского кирпича ручной формовки; закрытая туалетная комната в общем санузле; личная комната, разделенная на две зоны - для детей и родителей, двусторонним шкафом.",
    intro: resortImages["intro.jpg"],
    intro2: resortImages["intro2.jpg"],
    layoutBefore: resortImages["before.png"],
    layoutAfter: resortImages["after.png"],
    images: filterImages(resortImages, [
      "intro.jpg",
      "intro2.jpg",
      "before.png",
      "after.png",
    ]),
    columns: 1,
    objectColumns: 2,
  },
  {
    id: 22,
    name: "evolution",
    location: "Санкт-Петербург",
    apart: "ЖК «Е.ВОЛЮЦИЯ»",
    title: "Интерьер квартиры в ЖК «Е.ВОЛЮЦИЯ» с элементами стиля джапанди",
    metrs: "85",
    price: "340 000",
    priceRealization: "20 000 000",
    projectDevelopment: "42",
    realizationPeriod: null,
    style: "Современный с элементами джапанди",
    text: "Особенности: высокие потолки; двустороннее расположение квартиры; один гостевой и два личных санузла; большое количество встроенной корпусной мебели под места хранения в прихожей; общесемейное пространство со встроенным кухонным фронтом и развитой диванной группой; гамак между несущими колоннами, как дополнительная зона отдыха; рабочее место в мастер-спальне; разделение функциональных зон в детской: для отдыха и занятий - пространство комнаты, для игр - личная утеплённая лоджия.",
    intro: evolutionImages["intro.jpg"],
    intro2: evolutionImages["intro2.jpg"],
    layoutBefore: evolutionImages["before.png"],
    layoutAfter: evolutionImages["after.png"],
    images: filterImages(evolutionImages, [
      "intro.jpg",
      "intro2.jpg",
      "before.png",
      "after.png",
    ]),
    columns: 2,
    objectColumns: 1,
  },
  // {
  //   id: 23,
  //   name: "futurist",
  //   location: "Санкт-Петербург",
  //   apart: "ЖК «Футурист»",
  //   title: "Квартира в современном стиле с элементами модерна в ЖК «Футурист»",
  //   metrs: "116",
  //   price: "464 000",
  //   priceRealization: "18 000 000",
  //   projectDevelopment: "40",
  //   realizationPeriod: null,
  //   style: "Современный с элементами модерна",
  //   text: "Особенности: высокие потолки; двустороннее расположение квартиры; просторная планировка, наполненная «воздухом»; два санузла; общесемейное пространство с кухонным фронтом, обеденным столом и угловым диваном в три ряда; большое количество встроенной корпусной мебели под места хранения; многофункциональный кабинет для семьи айтишника и финансиста, состоящий из четырёх рабочих мест (у окна и в центре комнаты) и зоны отдыха (на диване); большая личная комната с объединённой лоджией и гардеробной со скрытым санузлом.",
  //   intro: futuristImages["intro.jpg"],
  //   intro2: futuristImages["intro2.jpg"],
  //   layoutBefore: futuristImages["before.png"],
  //   layoutAfter: futuristImages["after.png"],
  //   images: filterImages(futuristImages, [
  //     "intro.jpg",
  //     "intro2.jpg",
  //     "before.png",
  //     "after.png",
  //   ]),
  //   columns: 2,
  // },
];
