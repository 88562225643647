import React from "react";
import "./Hall.css";

import LoadableImage from "../LoadableImage/LoadableImage";

const Hall = ({ title, projects, buttonTitle, text }) => {
  return (
    <section className="hall">
      <h2 className="section-title">{title}</h2>
      <div className="works">
        {projects.map((project) => (
          <article key={project.id} className="work">
            <LoadableImage
              src={project.background}
              className="work__image"
              alt={project.name}
            />
            <div className="work__container">
              <h3 className="work__title">
                <span style={{ fontWeight: 600 }}>{project.metrs}</span>,{" "}
                {project.city}
              </h3>
              <h3 className="work__title">{project.name}</h3>
              <button className="button button_type_glasses">Подробнее</button>
            </div>
          </article>
        ))}
        <div
          className="works__overlay"
          style={
            text
              ? {
                  background:
                    "linear-gradient(0deg, rgba(28,28,28,1) 5%, rgba(0,0,0,0) 100%)",
                  height: 498,
                  paddingTop: 100,
                  boxSizing: "border-box",
                }
              : {}
          }
        >
          {text && (
            <p className="works__text">
              Лучше один раз посмотреть настоящий объект, чем 10 обзоров на
              разных сайтах
            </p>
          )}
          <button className="button">{buttonTitle}</button>
        </div>
      </div>
      {text && (
        <a className="hall__link" href="/">
          {buttonTitle}
        </a>
      )}
    </section>
  );
};

export default Hall;
