import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { gallery } from "../../utils/gallery";
import "./Gallery.css";
import LoadableImage from "../LoadableImage/LoadableImage";

const Gallery = () => {
  const [filter, setFilter] = useState("realized");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const filteredGallery = gallery.filter((project) => {
    if (filter === "realized") {
      return project.realizationPeriod;
    }
    if (filter === "design") {
      return !project.realizationPeriod;
    }
    return true;
  });

  const getProjectClass = (index: number) => {
    if (index === 0) {
      return ""; // Первая карточка — маленькая
    }
    const positionInCycle = (index - 1) % 4; // Чередование: 2 больших, 2 маленьких
    if (positionInCycle === 0 || positionInCycle === 1) {
      return "project_type_big"; // Большие карточки
    }
    return ""; // Маленькие карточки
  };

  return (
    <section className="gallery">
      <h2 className="gallery__title">Галерея работ</h2>
      <p className="gallery__text">
        Вдохновляющие интерьеры для современной жизни
      </p>
      <div className="gallery__buttons">
        <button
          className={`gallery__button ${
            filter === "realized" ? "gallery__button_active" : ""
          }`}
          onClick={() => setFilter("realized")}
        >
          Реализованные объекты
        </button>
        <button
          className={`gallery__button ${
            filter === "design" ? "gallery__button_active" : ""
          }`}
          onClick={() => setFilter("design")}
        >
          Дизайн интерьеров квартир
        </button>
      </div>
      <div className="gallery__list">
        {filteredGallery.map((project, index) => (
          <article
            className={`project ${getProjectClass(index)}`}
            key={project.id}
          >
            <Link className="project__link" to={`/gallery/${project.name}`}>
              <LoadableImage
                src={project.intro}
                className="project__image"
                alt={project.apart}
              />
              <div className="project__info">
                <h3 className="project__title">
                  {project.metrs} м², {project.location}
                </h3>
                <p className="project__text">{project.apart}</p>
              </div>
            </Link>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Gallery;
