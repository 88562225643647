import "./CustomVideoPlayer.css";
import React, { useRef, useState } from "react";

const CustomVideoPlayer = ({ videoSrc, poster }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="video">
      <video
        loop
        playsInline
        poster={poster}
        ref={videoRef}
        controls={isPlaying}
      >
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <button
        className={`video__button ${
          isPlaying ? "video__button__disabled" : ""
        }`}
        onClick={handlePlayPause}
        disabled={isPlaying}
      ></button>
    </div>
  );
};

export default CustomVideoPlayer;
