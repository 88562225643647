import "./Questions.css";
import React, { useState } from "react";

import openIcon from "../../images/Main/union.svg";

import { questions } from "../../utils/constants";

function Questions({ onCalcClick, onFeedbackClick }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleButtonClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="questions">
      <h2 className="questions__title">Вопросы и ответы</h2>
      {questions.map((data, index) => (
        <div
          key={index}
          className={`questions__container ${
            activeIndex === index ? "questions__container_active" : ""
          }`}
          onClick={() => handleButtonClick(index)}
        >
          <div className="questions__title-container">
            <h3 className="question__question">{data.question}</h3>
            <button type="button" className="button questions__button">
              <img
                src={openIcon}
                className={`question__open-image ${
                  activeIndex === index ? "questions__open-image_active" : ""
                }`}
                alt="Значок вверх"
              />
            </button>
          </div>
          <p
            className={`questions__answer ${
              activeIndex === index
                ? "questions__answer_active"
                : "questions__answer_disactive"
            }`}
          >
            {data.answer}
          </p>
        </div>
      ))}
    </section>
  );
}

export default Questions;
