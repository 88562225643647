import "./Advantages.css";
import React from "react";

import LoadableImage from "../LoadableImage/LoadableImage";

import { advantages } from "../../utils/constants";

import kalinaImage from "../../images/gallery/kalina-park/14.jpg";

const Advantages = () => {
  return (
    <section className="advantages">
      <h2 className="section-title">Наши преимущества</h2>
      <LoadableImage
        src={kalinaImage}
        className="advantages__image"
        alt="Визуализация интерьера"
      />
      <ul className="advantages__list">
        {advantages.map((advantage) => (
          <li className="advantage" key={advantage.id}>
            <p className="advantage__number">{advantage.id}</p>
            <div className="advantage__container">
              <h4 className="advantage__title">{advantage.title}</h4>
              <p className="advantage__text">{advantage.text}</p>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Advantages;
