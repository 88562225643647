import "./Scheme.css";
import React from "react";

import firstStepImage from "../../images/Main/step1.jpg";
import secondStepImage from "../../images/Main/evolition.png";

import { points } from "../../utils/constants";

import LoadableImage from "../LoadableImage/LoadableImage";

const Scheme = () => {
  return (
    <section className="scheme">
      <h2 className="section-title section-title_type_scheme">Схема работы</h2>
      <div className="scheme__container">
        <div className="step">
          <div className="step__info">
            <h3 className="step__title">Шаг 1.</h3>
            <h4 className="step__subtitle">Проектирование</h4>
          </div>
          <div className="points">
            {points.map((point) => (
              <article key={point.id} className="point">
                <h3 className="point__title">0{point.id}</h3>
                <p className="point__text">{point.first}</p>
              </article>
            ))}
          </div>
          <LoadableImage
            src={firstStepImage}
            className="step__image"
            alt="Фотография реализованного интерьера"
          />
        </div>
        <div className="step">
          <LoadableImage
            src={secondStepImage}
            className="step__image"
            alt="Фотография реализованного интерьера"
          />
          <div className="points">
            {points.map((point) => (
              <article key={point.id} className="point">
                <h3 className="point__title">0{point.id}</h3>
                <p className="point__text">{point.second}</p>
                {point.list && (
                  <ul className="point__list">
                    {point.list.map((item, index) => (
                      <li className="point__item" key={`${point.id}-${index}`}>
                        {item}
                      </li>
                    ))}
                  </ul>
                )}
              </article>
            ))}
          </div>
          <div className="step__info">
            <h3 className="step__title">Шаг 2.</h3>
            <h4 className="step__subtitle">Строительство</h4>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Scheme;
