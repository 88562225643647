import "./Promo.css";
import React from "react";

import promoImage from "../../images/Main/promo.png";

import LoadableImage from "../LoadableImage/LoadableImage";

const Promo = ({ handleOpenPopup }) => {
  return (
    <section className="promo">
      <LoadableImage
        src={promoImage}
        className="promo__image"
        alt="Визуализация интерьера"
      />
      <div className="promo__container">
        <div className="promo__info">
          <h1 className="promo__title">Готовые интерьеры под ключ</h1>
          <div className="promo__buttons">
            <button className="button">Галерея работ</button>
            <button
              className="button button_type_glass"
              onClick={handleOpenPopup}
            >
              Рассчитать стоимость
            </button>
          </div>
        </div>
      </div>
      <div className="promo__gradient"></div>
    </section>
  );
};

export default Promo;
