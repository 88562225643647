import "./About.css";
import React from "react";
import image from "../../images/about/about.png";
import { persons } from "../../utils/constants";

import LoadableImage from "../LoadableImage/LoadableImage";
import DrumSlider from "../DrumSlider/DrumSlider";

import useWindowSize from "../../hooks/useWindowSize";

const About = () => {
  const size = useWindowSize();

  const columns = [[], [], []];
  persons.forEach((person, index) => {
    columns[index % 3].push(person);
  });

  return (
    <section className="about">
      <div className="about__promo">
        <h2 className="about__title">НАША КОМАНДА</h2>
        <img
          className="about__image"
          src={image}
          alt="Общая фотография команды"
        />
      </div>
      <div className="about__columns">
        {size.width >= 576
          ? columns.map((column, columnIndex) => (
              <div className="about__column" key={columnIndex}>
                {column.map((person, personIndex) => (
                  <div className="about__person" key={personIndex}>
                    <LoadableImage
                      className="about__person-image"
                      src={person.imageGood}
                      alt={person.name}
                    />
                    <p className="about__person-title">{person.name}</p>
                    <p className="about__person-subtitle">{person.position}</p>
                  </div>
                ))}
              </div>
            ))
          : persons.map((person, personIndex) => (
              <div className="about__person" key={personIndex}>
                <LoadableImage
                  className="about__person-image"
                  src={person.imageGood}
                  alt={person.name}
                />
                <p className="about__person-title">{person.name}</p>
                <p className="about__person-subtitle">{person.position}</p>
              </div>
            ))}
      </div>
      <div className="about__slider">
        <DrumSlider />
      </div>
    </section>
  );
};

export default About;
