import "./Contacts.css";
import React from "react";

const Contacts = () => {
  return (
    <section className="contacts">
      <h2 className="contacts__title">
        СОДА готова разработать и реализовать интерьер любой сложности
      </h2>

      <div className="contacts__container">
        <div className="contacts__card">
          <h3 className="contacts__subtitle">Контакты</h3>
          <div className="contacts__links">
            <a
              className="contacts__link"
              href="tel:89335672715"
              target="_blank"
              rel="noreferrer"
            >
              +7 (933) 567-27-15
            </a>
            <a
              className="contacts__link"
              href="mailto:soda@penta.pro"
              target="_blank"
              rel="noreferrer"
            >
              soda@penta.pro
            </a>
          </div>
          <p className="contacts__text">
            197101, Санкт-Петербург, <br /> Большой проспект П. С. 65
          </p>
          <p className="contacts__text">
            Мы работаем в будние дни <br /> с 10:00 до 20:00
          </p>
        </div>
        <iframe
          className="contacts__map"
          src="https://yandex.ru/map-widget/v1/?um=constructor%3Ae9fca70e506dc9ebf9bdb1716ee6f4e28dce3eca795a84b2f76e9670d542627c&amp;source=constructor"
          frameborder="0"
        ></iframe>
      </div>
    </section>
  );
};

export default Contacts;
