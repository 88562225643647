import "./Directions.css";
import React from "react";

import firstDirectionImage from "../../images/Main/first-direction.png";
import secondDirectionImage from "../../images/Main/second-direction.png";

import LoadableImage from "../LoadableImage/LoadableImage";

const Directions = () => {
  return (
    <section className="directions">
      <h2 className="section-title">Основные направления</h2>
      <div className="directions__container">
        <div className="direction">
          <LoadableImage
            src={firstDirectionImage}
            className="direction__image"
            alt="Изображение квартиры"
          />
          <div className="direction__container">
            <h3 className="direction__title">Дизайн квартир под ключ</h3>
          </div>
        </div>
        <div className="direction direction_type_second ">
          <LoadableImage
            src={secondDirectionImage}
            className="direction__image"
            alt="Изображение дома"
          />
          <div className="direction__container">
            <h3 className="direction__title direction__title_type_second">
              Дизайн загородных домов под ключ
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Directions;
