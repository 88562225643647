import "./Navigation.css";
import React from "react";
import { Link } from "react-router-dom";

function Navigation({ setOpen }) {
  return (
    <nav className={`navigation`}>
      <ul className="navigation__list">
        <li className="navigation__element" onClick={setOpen}>
          <div className="navigation__el">
            <Link to="/about-us" className="navigation__link">
              О НАС
            </Link>
          </div>
        </li>
        <li className="navigation__element" onClick={setOpen}>
          <div className="navigation__el">
            <Link to="/gallery" className="navigation__link">
              ГАЛЕРЕЯ РАБОТ
            </Link>
          </div>
        </li>
        <li className="navigation__element" onClick={setOpen}>
          <div className="navigation__el">
            <Link className="navigation__link">УСЛУГИ</Link>
          </div>
        </li>
        <li className="navigation__element" onClick={setOpen}>
          <div className="navigation__el">
            <Link to="/price" className="navigation__link">
              ЦЕНЫ
            </Link>
          </div>
        </li>
        <li className="navigation__element" onClick={setOpen}>
          <div className="navigation__el">
            <Link to="/contacts" className="navigation__link">
              КОНТАКТЫ
            </Link>
          </div>
        </li>
      </ul>
    </nav>
  );
}

export default Navigation;
