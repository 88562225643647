import "./Form.css";
import React, { useState, useEffect } from "react";
import useFormsValidation from "../../hooks/useFormsValidation";
import usePhoneMask from "../../hooks/usePhoneMask";
import InputWithError from "../InputWithError/InputWithError";

import useWindowSize from "../../hooks/useWindowSize";

const Form = ({ title, isInputsDisabled }) => {
  const { handleChangeInput, inputs, isError, messageError, setInputs } =
    useFormsValidation({});
  const { onPhonePaste, onPhoneInput, onPhoneKeyDown } =
    usePhoneMask(handleChangeInput);
  const [selectedContactMethod, setSelectedContactMethod] =
    useState("Telegram");

  const handleContactMethodChange = (method) => {
    setSelectedContactMethod(method);
  };

  const size = useWindowSize();

  const handleMouseDownInput = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    if (size.width <= 576 && size.width !== 0) {
      setSelectedContactMethod("Звонок");
    }
    if (size.width >= 1024 && size.width !== 0) {
      setSelectedContactMethod("Telegram");
    }
  }, [size.width]);

  return (
    <form className="form">
      {title && <h3 className="form__title">{title}</h3>}
      <div className="form__section">
        <p className="form__input-title">Как к Вам обращаться?</p>
        <InputWithError
          name="name"
          value={inputs.name}
          onChange={handleChangeInput}
          placeholder="Имя"
          pattern="^[\-\sa-zA-Zа-яА-Я]*$"
          isError={isError.name}
          messageError={messageError.name}
          isInputsDisabled={isInputsDisabled}
        />
      </div>
      <div className="form__section">
        <p className="form__input-title">Выберите удобный способ связи:</p>
        <div className="form__buttons">
          <button
            type="button"
            className={`form__button ${
              selectedContactMethod === "Telegram" ? "form__button_active" : ""
            }`}
            onClick={() => handleContactMethodChange("Telegram")}
          >
            Telegram
          </button>
          <button
            type="button"
            className={`form__button ${
              selectedContactMethod === "WhatsApp" ? "form__button_active" : ""
            }`}
            onClick={() => handleContactMethodChange("WhatsApp")}
          >
            WhatsApp
          </button>
          <button
            type="button"
            className={`form__button ${
              selectedContactMethod === "Звонок" ? "form__button_active" : ""
            }`}
            onClick={() => handleContactMethodChange("Звонок")}
          >
            Звонок
          </button>
        </div>
        <InputWithError
          name="phone"
          value={inputs.phone}
          onChange={onPhoneInput}
          onPaste={onPhonePaste}
          onKeyDown={onPhoneKeyDown}
          placeholder="Телефон"
          pattern="^(\+7|8)\s?\(\d{3}\)\s?\d{3}-\d{2}-\d{2}$"
          isError={isError.phone}
          messageError={messageError.phone}
          isInputsDisabled={isInputsDisabled}
        />
        <div
          className={`form__telegram ${
            selectedContactMethod === "Telegram" ? `form__telegram_active` : ``
          }`}
        >
          <p className="form__or">Или</p>
          <div className="inputs-container">
            <div
              className={`input-container ${
                isError.path ? `input-container_type_error` : ""
              }`}
            >
              <p
                className={`input-path ${
                  inputs.path ? "input-path_active" : ""
                }`}
                onMouseDown={handleMouseDownInput}
              >
                @
              </p>
              <input
                className={`input input_type_path ${
                  isError.path ? `input_type_error` : ""
                }`}
                name="path"
                type="text"
                value={inputs.path || ""}
                onChange={handleChangeInput}
                placeholder="nickname"
                pattern="[A-Za-z0-9]*"
                disabled={isInputsDisabled}
                required
              />
            </div>
            <span
              className={`input-error ${
                isError.path ? "input-error_active" : ""
              }`}
            >
              {isError.path &&
                (messageError.path === `Введите данные в указанном формате.`
                  ? `Неверный формат.`
                  : messageError.path)}
            </span>
          </div>
        </div>
      </div>
      <button className="button" style={{ width: `100%`, marginTop: -8 }}>
        Отправить заявку
      </button>
    </form>
  );
};

export default Form;
