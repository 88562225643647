import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import drumArrow from "../../images/Main/drum-arrow.svg";
import LoadableImage from "../LoadableImage/LoadableImage";
import { drumSettings } from "../../utils/constants";

const DrumSlider = () => {
  const slider1Ref = useRef(null);
  const slider2Ref = useRef(null);

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  useEffect(() => {
    if (slider1Ref.current && slider2Ref.current) {
      setNav1(slider1Ref.current);
      setNav2(slider2Ref.current);
    }
  }, []);

  const changeSlide = (sliderRef, direction) => {
    if (sliderRef.current) {
      if (direction === "next") {
        sliderRef.current.slickNext();
      } else if (direction === "prev") {
        sliderRef.current.slickPrev();
      }
    }
  };

  let settings1 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    centerMode: true,
    centerPadding: "29px",
    focusOnSelect: true,
    arrows: false,
    initialSlide: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          className: "drum__list slider variable-width",
          dots: false,
          infinite: true,
          speed: 1000,
          slidesToShow: 3,
          slidesToScroll: 1,
          vertical: false,
          verticalSwiping: false,
          centerMode: false,
          centerPadding: "0",
          focusOnSelect: true,
          arrows: false,
          initialSlide: 2,
          variableWidth: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          className: "drum__list slider variable-width",
          dots: false,
          infinite: true,
          speed: 1000,
          slidesToShow: 2,
          slidesToScroll: 1,
          vertical: false,
          verticalSwiping: false,
          centerMode: false,
          centerPadding: "0",
          focusOnSelect: true,
          arrows: false,
          initialSlide: 2,
          variableWidth: true,
        },
      },
    ],
  };

  let settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    initialSlide: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: true,
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: false,
          verticalSwiping: false,
          arrows: false,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <div className="drum">
      <div className="drum__container">
        <button
          className="drum__button"
          onClick={() => changeSlide(slider1Ref, "prev")}
        >
          <img
            src={drumArrow}
            className="drum__button-image"
            alt="Стрелка вверх"
          />
        </button>
        <Slider
          asNavFor={nav2}
          className="drum__list"
          ref={slider1Ref}
          {...settings1}
        >
          {drumSettings.map((item) => (
            <article className="drum__item" key={item.id}>
              {item.name}
            </article>
          ))}
        </Slider>
        <button
          className="drum__button drum__button_type_down"
          onClick={() => changeSlide(slider1Ref, "next")}
        >
          <img
            src={drumArrow}
            className="drum__button-image"
            alt="Стрелка вниз"
          />
        </button>
      </div>
      <Slider asNavFor={nav1} className="areas" ref={slider2Ref} {...settings2}>
        {drumSettings.map((area) => (
          <article className="area" key={area.id}>
            <div className="area__container">
              {area.params.map((param, index) => (
                <p className="area__text" key={index}>
                  {param}
                </p>
              ))}
              <div className="area__images">
                {area.images.map((image, index) => (
                  <LoadableImage
                    src={image}
                    className="area__image"
                    alt="Изображение сотрудника"
                    key={index}
                  />
                ))}
              </div>
            </div>
          </article>
        ))}
      </Slider>
    </div>
  );
};

export default DrumSlider;
