import "./Burger.css";
import React from "react";
import { useState, useEffect } from "react";
import Navigation from "../Navigation/Navigation";
import Logo from "../Logo/Logo";

import telegramImage from "../../images/Footer/telegram.svg";
import whatsappImage from "../../images/Footer/whatsapp.svg";
import mailImage from "../../images/Footer/mail.svg";
import youtubeImage from "../../images/Footer/youtube.svg";
import instaImage from "../../images/Footer/insta.svg";

function Burger({ setBurger, onCalcClick, onFeedbackClick }) {
  const [isOpen, setOpen] = useState(false);

  const handleCloseButtonClick = () => {
    setOpen(false);
    setTimeout(setBurger, 300);
  };

  useEffect(() => {
    setTimeout(setOpen(true), 300);
  }, []);

  return (
    <div className="burger">
      <div
        className={`burger__container ${
          isOpen ? `burger__container_active` : `burger__container_deactive`
        }`}
      >
        <div className="burger__middle">
          <div className="burger__header">
            <div className="burger__logo">
              <Logo />
            </div>
            <button
              className="burger__close-button"
              onClick={handleCloseButtonClick}
            ></button>
          </div>
          <Navigation
            onCalcClick={onCalcClick}
            onFeedbackClick={onFeedbackClick}
            setOpen={handleCloseButtonClick}
            isOpen={isOpen}
          />
        </div>
        <div className="burger__footer">
          <div className="burger__nav">
            {/* prettier-ignore */}
            <pre className="burger__link">
            197101, Санкт-Петербург                           Большой проспект П.С., 65
          </pre>
            <a
              className="burger__link"
              href="tel:81234567890"
              target="_blank"
              rel="noreferrer"
            >
              +7 (123) 456-78-90
            </a>
            <a
              className="burger__link"
              href="mailto:soda@penta.pro"
              target="_blank"
              rel="noreferrer"
            >
              soda@penta.pro
            </a>
          </div>

          <ul className="burger__socials">
            <li className="burger__social">
              <a
                className="burger__social-link"
                href="/about-us"
                rel="noreferrer"
              >
                <img
                  src={telegramImage}
                  className="burger__social-image"
                  alt="Иконка соц. сети"
                />
              </a>
            </li>
            <li className="burger__social">
              <a
                className="burger__social-link"
                href="/about-us"
                rel="noreferrer"
              >
                <img
                  src={whatsappImage}
                  className="burger__social-image"
                  alt="Иконка соц. сети"
                />
              </a>
            </li>
            <li className="burger__social">
              <a
                className="burger__social-link"
                href="/about-us"
                rel="noreferrer"
              >
                <img
                  src={mailImage}
                  className="burger__social-image"
                  alt="Иконка соц. сети"
                />
              </a>
            </li>
            <li className="burger__social">
              <a
                className="burger__social-link"
                href="/about-us"
                rel="noreferrer"
              >
                <img
                  src={youtubeImage}
                  className="burger__social-image"
                  alt="Иконка соц. сети"
                />
              </a>
            </li>
            <li className="burger__social">
              <a
                className="burger__social-link"
                href="/about-us"
                rel="noreferrer"
              >
                <img
                  src={instaImage}
                  className="burger__social-image"
                  alt="Иконка соц. сети"
                />
              </a>
            </li>
          </ul>
          {/* prettier-ignore */}
          <pre className="burger__policy">
            © 2024 Сода                                                                                                    Отправляя любую форму, вы соглашаетесь с Политикой конфиденциальности
          </pre>
        </div>
      </div>
      <div
        className={`burger__overlay ${
          isOpen ? `burger__overlay_active` : `burger__overlay_deactive`
        }`}
        onClick={handleCloseButtonClick}
      ></div>
    </div>
  );
}

export default Burger;
