import "./Logo.css";
import React from "react";

import logo from "../../images/logo/logo-big.svg";

const Logo = () => {
  return (
    <a className="logo" href="/">
      <img src={logo} className="logo__image" alt="Логотип компании" />
    </a>
  );
};

export default Logo;
