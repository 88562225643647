import "./Price.css";
import priceImage from "../../images/Main/price.png";
import Form from "../Form/Form";

import LoadableImage from "../LoadableImage/LoadableImage";

const Price = () => {
  return (
    <section className="price">
      <div className="price__form">
        <Form title="Получите точную стоимость дизайн-проекта" />
      </div>
      <LoadableImage
        src={priceImage}
        className="price__image"
        alt="Фотография интерьера"
      />
    </section>
  );
};

export default Price;
