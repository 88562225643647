import "./Feedback.css";
import React, { useRef, useState, useEffect } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { feedbacks } from "../../utils/constants";

const Feedback = () => {
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    slidesToShow: 3,
    arrows: false,
    focusOnSelect: true,
    centerMode: true,
    centerPadding: "0",
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 4000,
          pauseOnHover: true,
          slidesToShow: 2,
          arrows: false,
          focusOnSelect: true,
          centerMode: true,
          centerPadding: "0",
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          dots: true,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 4000,
          pauseOnHover: true,
          slidesToShow: 1,
          arrows: false,
          focusOnSelect: true,
          centerMode: true,
          centerPadding: "0",
          swipeToSlide: true,
        },
      },
    ],
  };

  return (
    <section className="feedbacks">
      <h2 className="feedbacks__title">Отзывы</h2>
      <Slider className="feedbacks__container" ref={sliderRef} {...settings}>
        {feedbacks.map((feedback) => (
          <article className="feedback" key={feedback.id}>
            <p className="feedback__text">{feedback.text}</p>
            <h4 className="feedback__title">{feedback.name}</h4>
            <p className="feedback__subtitle">
              {`${feedback.object}, ${feedback.city}`}
            </p>
          </article>
        ))}
      </Slider>
    </section>
  );
};

export default Feedback;
